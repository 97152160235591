import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Button, Label, Spinner } from "reactstrap";
import "./AccountSection.scss";
import { useSelector, useDispatch } from "react-redux";
import amplifyApiService from "services/AmplifyApiService";
import constants from "../../constants";
import moment from "moment";
import { getSubscriptionPosition } from "tools/utills";
import { getUser } from "redux/features/user/userSlice";
import { signOut } from "redux/features/auth/authSlice";

const AccountSection = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authInfo?.info);
  const uss = useSelector((state) => state?.user?.info);
  const [subscription, setSubscription] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const sub = getSubscriptionPosition(uss);
    setSubscription(sub);
  }, [uss]);
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (user?.email && !user?.emailVerified) {
      history.push("/verify-email");
    }
  }, [user?.email, user?.emailVerified]);

  useEffect(() => {
    if (user?.userId) {
      dispatch(getUser(user?.userId));
    }
  }, [user?.userId]);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const results = await amplifyApiService.post("payments/portal", {
      email: user?.email,
    });
    if (results) {
      // window.open(results?.data?.billingUrl);
      window.location.href = results?.data?.billingUrl;
    }
  };
  const logOut = async (e) => {
    e.preventDefault();
    history.replace({
      pathname: "/",
    });
    dispatch(signOut());
  };

  const getPackageName = (unit) => {
    switch (unit) {
      case constants.BillingPeriodUnit.MONTH:
        return "Monthly";
      case constants.BillingPeriodUnit.YEAR:
        return "Yearly";
      case constants.BillingPeriodUnit.DAY:
        return "Daily";
      default:
        return "";
    }
  };

  const getPremBtn = () => {
    history.push({
      pathname: "/packages",
    });
  };

  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start account-title">My Details</h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <div className="account-name-row">
              <Col xs="6" className="pl-0 pr-0">
                <Label className="account-label">First Name</Label>
                <br />
                <Label>{uss?.first_name || "First Name"}</Label>
                <br />
              </Col>
              <Col xs="6" className="pl-0 pr-0">
                <Label className="account-label">Last Name</Label>
                <br />
                <Label>{uss?.last_name || "Last Name"}</Label>
                <br />
              </Col>
            </div>
            <br />
            <Label className="account-label">Email</Label>
            <br />
            <Label>{user?.email}</Label>
            <br />
            <br />
          </Col>
        </Row>

        {subscription === constants.SubscriptionPosition.EXPIRED ||
          subscription === constants.SubscriptionPosition.OVERDUE ||
          subscription === constants.SubscriptionPosition.DAILY ||
          subscription === constants.SubscriptionPosition.MONTHLY ||
          subscription === constants.SubscriptionPosition.TRIAL ||
          subscription === constants.SubscriptionPosition.YEARLY ? (
          <div>
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <h1 className="text-start account-title">My Subscription</h1>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <Label className="account-label">Plan</Label>
                <br />
                <Label>{`Premium (${getPackageName(
                  uss?.billing_interval
                )})`}</Label>
                <br />
                <Label>{`$${uss?.next_billing_amount / 100} NZD per ${uss?.billing_interval}`}</Label>
                <br />
                <br />
                {/* {uss?.next_billing_date ? <><Label className="account-label-italic">
                    {`Auto renews on ${moment(uss?.next_billing_date).format(
                      "DD MMMM YYYY"
                    )}`}
                  </Label><br /></> : <div />} */}
                <div className="email-not-verified-link-button">
                  <Button
                    block
                    className="manage-subscription-button"
                    onClick={handleClick}
                  >
                    {loading ? (
                      <Spinner size="sm" type="grow" />
                    ) : (
                      `Manage Subscription ${" "}`
                    )}
                  </Button>
                </div>
                <div className="email-not-verified-link-button">
                  <Button
                    block
                    className="account-sign-out-button"
                    onClick={logOut}
                  >
                    Sign Out
                  </Button>
                </div>
                <br />
              </Col>
            </Row>
          </div>
        ) : (
          <Row>
            <Col className="ml-auto mr-auto" md="4">
              <div className="account-split-container">
                <div>
                  <Label className="account-label">Membership</Label>
                  <br />
                  {/* Condition based on Free or Premium Version */}
                  <Label>Free Version</Label>
                  <br />
                </div>
                <div>
                  <Button onClick={getPremBtn} className="get-premium-button">
                    Get Premium
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {/* <Row>
          <Col className="ml-auto mr-auto" md="4">
            <div className="generic-account-ad-slot-container generic-ad-slot-center">
              <img
                src="https://gsn-photos.s3.ap-southeast-2.amazonaws.com/rectangle_maz_d0786e2f31.jpg"
                className="generic-ad-slot"
              />
            </div>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};
export default AccountSection;
