import Description from "components/Description/Description";
import Axios from "axios";
import React, {useState} from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';
import "./ContactUsSection.scss";

const ContactUsSection = () => {
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const results = await Axios.post(`https://10f5iarxy9.execute-api.ap-southeast-2.amazonaws.com/prod/email`,{
            name: e.target.name.value,
            email: e.target.email.value,
            body: e.target.message.value,
            subject: 'Support Request'
        },{headers:{
            'X-Api-Key': 'ZdoncwT4zd2IIsftcLaQU6Bq7c3avkIv1Il36jG0'
          }}
    );
    document.getElementById("contact-form").reset();
    setDisabled(false);
  }
  return (
    <div className="section section-sections" style={{minHeight:'600px', paddingBottom: '20px'}}>
      <Container>
          <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="text-start contact-us-title">
                Send us a message
                </div>
              </Col>
          </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                  {/* <h2 className="title">Send us a message</h2> */}
                  <Description 
                    value= {`Kia ora!
 `}
                  />
                  <Description 
                    value='
For Aroha Surf Club donations please go to [Aroha Surf Club](https://www.goodsurfnow.co.nz/post/aroha-surf-club)'
                  /> 
                  <Description 
                    value='
For support, feedback, and to tell us about your latest twin-fin, hit us up at [support@goodsurfnow.com](mailto:support@goodsurfnow.com)'
                  /> 
                  <br />
                  {/* <Form id="contact-form" method="post" role="form" onSubmit={handleSubmit}>
                    <label>Your name</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                      <InputGroupText className="contact-form-email">
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="Your Name..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Email address</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="contact-form-email">
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>Your message</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        disabled={disabled}
                        className={`btn-raised btn-round ${disabled ? 'submit-disabled' : ''}`}
                        color="info"
                        defaultValue="Contact Us"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form> */}
                </Col>
            </Row>
      </Container>
    </div>
  );
};
export default ContactUsSection;
