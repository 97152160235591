import React, { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./PaymentReturnSection.scss";
import { useDispatch, useSelector } from "react-redux";
import amplifyApiService from "services/AmplifyApiService";
import { getUser } from "redux/features/user/userSlice";

const PaymentReturnSection = ({ data }) => {
  const [status, setStatus] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [customerEmail, setCustomerEmail] = useState("");
  const subscription = useSelector((state) => state.subscription.info);
  const user = useSelector((state) => state?.authInfo?.info);
  const uss = useSelector((state) => state?.user?.info);
  // console.log(user);
  useEffect(() => {
    if (!user?.userId || !subscription?.selectedPackage) return;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const callApi = async () => {
      // can pass the gsn username to the backend so that we can process both information
      const results = await amplifyApiService.get(
        `payments/status?session_id=${sessionId}&user_id=${user?.userId}&package_id=${subscription?.selectedPackage}`
      );
      if (results) {
        if (results?.data?.status === "complete") {
          dispatch(getUser(user?.userId));
        }
        setStatus(results?.data?.status);

        setCustomerEmail(results?.data?.customer_email);
      }
    };

    callApi().catch((err) => {
      console.log(err);
    });
    
  }, [user?.userId, subscription?.selectedPackage]);

  const handleClick = () => {
    history.push("/post/gsn-cams-project");
  };

  const handleTabClick = () => {
    history.push("/post/add-app-24");
  };

  const handleForecastClick = () => {
    history.push("/post/how_to");
  };
  if (status === "open") {
    history.push("/checkout");
    return null;
  }
  if (status === "complete") {
    return (
      <div
        className="section section-sections"
        style={{ paddingBottom: "20px" }}
      >
        <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Row>
            <Col className="ml-auto mr-auto" md="4">
              <h1 className="text-start login-title">Payment Successful</h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <br />
              <p className="payment-success-message">
                Chuurrr <span>{uss?.first_name}</span>
              </p>
              <p className="payment-success-message">
                Your <b>GOOD SURF NOW premium membership</b> is now active. Get
                frothy with access to our growing network of HD-as home grown{" "}
                <span>
                  <b>surf cams - </b>
                </span>{" "}
                YEEEEWWWW!!!
              </p>
              <p className="payment-success-message">
                Here’s some things you might like to know:
              </p>
              <p className="payment-success-message multi-links-list">
                <span className="surf-cams-link" onClick={handleTabClick}>
                  <b>How to save GSN to your phone/tablet like an app</b>
                </span>
              </p>
              <p className="payment-success-message multi-links-list">
                <span className="surf-cams-link" onClick={handleForecastClick}>
                  <b>How to read the forecasts (if you’re new to GSN)! </b>
                </span>
              </p>
              <br />
              <p className="payment-success-message">
                If you have any questions hit us up on the contact page at the
                bottom of the page or at{" "}
                <a href="mailto:support@goodsurfnow.com">support@goodsurfnow.com</a>{" "}
              </p>
              <p className="payment-success-message">
                Chuuurrr again frother, you rule!
              </p>
              <p className="payment-success-message">
                <b>Benno</b>
              </p>
              <br />
              <br />
              <p
                className="payment-success-message get-started"
                onClick={() => history.push("/")}
              >
                <b>GET STARTED!</b>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">
              <span>
                Payment Processing <Spinner size="sm" type="grow" />
                <Spinner size="sm" type="grow" />
                <Spinner size="sm" type="grow" />
              </span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="6"></Col>
        </Row>
      </Container>
    </div>
  );
};
export default PaymentReturnSection;
