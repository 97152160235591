import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import './Premium.scss';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubscriptionPosition } from "tools/utills";
import constants from "../../constants";

const Premium = () => {
    const history = useHistory();
    const userInfo = useSelector((state) => state?.user?.info) ?? null;

    const [subscriptionPosition, setSubscriptionPosition] = useState(null);

    useEffect(() => {
        const position = getSubscriptionPosition(userInfo);
        setSubscriptionPosition(position);
    }, [userInfo]);

    const handleClick = () => {
        switch (subscriptionPosition) {
            case constants.SubscriptionPosition.ANONYMOUS:
            case constants.SubscriptionPosition.EXPIRED:
            case constants.SubscriptionPosition.UNKNOWN:
                history.push({ pathname: "/sign-up" });
                break;
            // case constants.SubscriptionPosition.TRIAL:
            // case constants.SubscriptionPosition.MONTHLY:
            // case constants.SubscriptionPosition.YEARLY:
            // case constants.SubscriptionPosition.BLOCKED:
            // case constants.SubscriptionPosition.DAILY:
            // case constants.SubscriptionPosition.OVERDUE:
            case constants.SubscriptionPosition.UNSUBSCRIBED:
            case constants.SubscriptionPosition.CANCELLED:
                history.push({ pathname: "/packages" });
                break;
            default:
                history.push({ pathname: "/sign-up" });
                break;
        }
    };
    return !userInfo?.user_subscription || userInfo?.status === 'cancelled' ? (
        <Container className="premium-og-container">
            <Row className="p-0 pt-4 pb-4 m-0 mb-4 mt-4" >
                <Col xs={12} sm={12} md={6}>
                    <video
                        src="https://d19sqy6pdj9b8k.cloudfront.net/premium_video.mov"
                        autoPlay
                        loop
                        muted
                        style={{ width: '100%' }}
                        playsInline
                    />
                </Col>
                <Col xs={12} sm={12} md={6} className="pt-3 pt-md-0">
                    <div className="home-og-promotion-container">
                        <div className="home-og-hookup-big">
                            The O.G. HOOKUP
                        </div>
                        {/* <div className="home-be-first-text">
                            Be one of the first paid subscriber to get
                        </div> */}
                        <div className="home-fifty-percent">
                            <b>40% OFF</b> <i>FOR LIFE*</i>
                        </div>
                        <div className="home-use-code">Use code <span className="home-life-forty">LIFE40</span> at checkout</div>
                        <div className="home-premium-prices">
                            <span className="home-premium-cross">NZ$60/year</span><span><b>NZ$36/year ($3/month)</b></span>
                        </div>
                        <div
                            className="home-package-price-button"
                            style={{
                                backgroundColor: "#fd2dbf",
                            }}
                            onClick={handleClick}
                        >
                            SUBSCRIBE
                        </div>
                        <br />
                        <div className="home-tnc-apply">*Terms and conditions apply</div>
                        <div className="home-tnc-apply">Offer Ends Soon!</div>
                    </div>
                </Col>
            </Row>
        </Container>
    ) : <br />;
}

export default Premium;