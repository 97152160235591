import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FooterBlack from "components/Footers/FooterBlack.js";
import ApiService from "services/ApiService.js";
import _ from "lodash";
import LocationFooter from "../components/Locations/LocationFooter.js";
import AccountSection from "./account-sections/AccountSection.js";
import GoodSurfNavBarProd from "../components/Navbars/GoodSurfNavBarProd.js";

function Account() {
  const history = useHistory();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    (async function callApi() {
      try {
        const locations = await ApiService.get("locations?_limit=-1");
        setLocations(_.orderBy(locations, (item) => item.order));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  const handleSelectPost = (post) => {
    // should redirect to the post page
    history.push({
      pathname: `/post/${post.slug}`,
      state: { post },
    });
  };
  return (
    <>
      {/* <DropdownWhiteNavbar locations={locations} /> */}
      <Helmet>
        <title>Good Surf Now!</title>
        <meta property="og:title" key="og:title" content="Good Surf Now!" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.goodsurfnow.co.nz"
        />
        <meta
          property="og:description"
          key="og:description"
          content="The best surf website in Aotearoa / New Zealand. Supremely accurate video surf forecasts, forecast graphs, links to cams, wave buoys and the latest surf videos and news"
        />
        <link rel="canonical" href="https://www.goodsurfnow.co.nz/" />
        <meta
          name="description"
          content="The best surf website in Aotearoa / New Zealand. Supremely accurate video surf forecasts, forecast graphs, links to cams, wave buoys and the latest surf videos and news"
        />
      </Helmet>
      <GoodSurfNavBarProd locations={locations} />
      {/* <GoodSurfNavBar /> */}
      <div className="wrapper">
        <AccountSection />
        <LocationFooter locations={locations} />
        <FooterBlack />
      </div>
    </>
  );
}

export default Account;
