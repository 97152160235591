import React, { useEffect, useRef, useState } from "react";
import usePageVisibility from "hooks/useVisibility";
import { useInView } from "react-intersection-observer";
import "./GSNCam.scss";
import CamMask from "components/HomeCams/CamMask";
import liveCam from "../../assets/cams/cam_icon.png";

const GSNCam = ({ user, cam, location }) => {
  const videoRef = useRef(null);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const [inViewRef, inView] = useInView({
    triggerOnce: false, // Only trigger once when the video enters the viewport
  });

  const visibility = usePageVisibility();

  const containerRef = useRef();

  useEffect(() => {
    function handleResize() {
      setWidth(containerRef?.current?.clientWidth);
      // console.log("Test", width);
      let height = (width / 16) * 9;
      setHeight(height);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    const interval = setInterval(() => {
      pause();
    }, [90 * 1000]);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!visibility || !inView) {
      pause();
    }
  }, [visibility, inView]);

  const play = () => {
    const iframe = videoRef.current;
    if (iframe) {
      try {
        iframe.contentWindow.postMessage(
          JSON.stringify({ funcName: "play", args: null }),
          "*"
        );
        console.log("Video is playing");
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  const pause = () => {
    const iframe = videoRef.current;
    if (iframe) {
      try {
        iframe.contentWindow.postMessage(
          JSON.stringify({ funcName: "stop", args: null }),
          "*"
        );
      } catch (e) {
        console.log(e.message);
      }
    }
  };

  const renderMask = () => (
    <div ref={containerRef} className="free-user-mask">
      <img
        alt={`${cam.name}-thumbnail`}
        className="cam-location-image"
        src={cam?.thumbnails[0]?.url}
      ></img>
      {/* <img
        className="gsn-cam-thumbnail-icon"
        src={liveCam}
        alt="live_cam"
        style={{ width: "44px", height: "27px" }}
      ></img> */}
      <CamMask />
    </div>
  );
  const renderIframe = () => (
    <div ref={inViewRef}>
      <div ref={containerRef} id="caster-video-container" className="caster-video">
      <iframe
          src={`${cam?.link}?iframeMsg=true`}
          frameBorder={0}
          ref={videoRef}
          allowFullScreen={true}
          allow="autoplay"
          data-ready={true}
          id="player1"
          width="100%"
          height={height}
          className="desktop-video"
        ></iframe>
      </div>
    </div>
  );

  if (cam) {
    if (user?.signedIn) {
      // if (cam?.authorized && cam?.link) {
      if (cam?.link) {
        return renderIframe();
      } else {
        return renderMask();
      }
    } else {
      return renderMask();
    }
  } else {
    return <div />;
  }
  // return cam?.link ? renderIframe() : renderMask();
  // return  renderIframe();
};

export default GSNCam;
