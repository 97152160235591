import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, Spinner } from "reactstrap";
import "./SignInSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signIn, clearAuthError, clearPwdRestReq } from "../../redux/features/auth/authSlice";
import constants from "../../constants";

const SignInSection = ({ data }) => {
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state?.authInfo);
  // const userInfo = useSelector((state) => state?.user?.info);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });
  useEffect(()=> {
    dispatch(clearAuthError());
  }, []);
  useEffect(() => {
    if (authInfo?.info?.email && authInfo?.info?.signedIn) {
      history.push("/");
    }
    if (
      authInfo?.info?.email &&
      authInfo?.error?.code ===
        constants.AmplifyExceptions.USER_NOT_CONFIRMED_EXCEPTION
    ) {
      history.push("/verify-email");
    }
  }, [authInfo?.info?.email, authInfo?.info?.signedIn, authInfo?.error?.code]);

  const onSubmit = async (data) => {
    // console.log(data);
    dispatch(clearAuthError());
    dispatch(signIn({ email: data?.email, password: data?.password }));
    // clear auth error
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Sign In</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="email"
                label="email"
                type="email"
                inputRef={register("email", { required: true })}
                name="email"
                invalid={!!errors.email}
                error="This field email is required"
              />
              <TextInput
                placeholder="password"
                label="password"
                type="password"
                inputRef={register("password", { required: true })}
                name="password"
                invalid={!!errors.password}
                error="This field password is required"
                isPassword={true} 
              />
              {authInfo?.error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {authInfo?.error?.message}
                </span>
              )}
              <Button block className="login-button">
                {authInfo.status === "pending" ? (
                  <Spinner size="sm" type="grow" />
                ) : (
                  `Login ${" "}`
                )}
              </Button>
            </Form>
            <div className="extra-links-sign-in">
              <Button
                className="create-account"
                color="neutral"
                tag={Link}
                to="/sign-up"
              >
                Create Account
              </Button>
              <Button
                onClick={() => {
                  dispatch(clearPwdRestReq());
                  history.push("/forgot-password");
                }}
                className="forgot-password"
                color="neutral"
                // tag={Link}
                // to="/forgot-password"
              >
                Forgot Password?
              </Button>
            </div>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SignInSection;
