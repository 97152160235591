import React, { useEffect, useState } from "react";
import constants from "../../constants";
import "./CamMask.scss";
import { useHistory } from "react-router-dom";
import { getSubscriptionPosition } from "tools/utills";
import { useSelector } from "react-redux";
import walrus from '../../assets/cams/walrus-lock.png';
import join from '../../assets/nav/join.png';
import login from '../../assets/nav/login.png';

const CamMask = ({ size }) => {
  const userInfo = useSelector((state) => state?.user?.info);
  const [subscriptionPosition, setSubscriptionPosition] = useState(null);

  useEffect(() => {
    const position = getSubscriptionPosition(userInfo);
    setSubscriptionPosition(position);
  }, [userInfo]);

  const history = useHistory();

  const handleSignUp = () => {
    history.push({ pathname: "/sign-up" });
  };
  const handleSignIn = () => {
    history.push({ pathname: "/sign-in" });
  };
  const handleSubscribe = () => {
    history.push({ pathname: "/packages" });
  };


  // const handleManageSubscription = () => {
  //   history.push({ pathname: "/account" });
  // }
  const renderJoin = () => (
    <div
      className="live-cam-mask-text-container"
      style={{
        fontSize: size === constants.MaskSize.SMALL ? "10px" : "18px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
        <div className="walrus-join-text">
          <div>To extend your <b>FREE</b> access</div>
          <button
            className="walrus-join-button"
            onClick={() => handleSignUp()}
          >
            <img
              alt="gsn-nav"
              src={join}
              style={{ width: "60px", height: "30px" }}
            />
          </button>
          <div><b>NO CREDIT CARD REQUIRED!</b></div>
        </div>
        <img
          alt={`walrus-lock`}
          className="walrus-lock-image"
          src={walrus}
        ></img>
        <div>
          <span><b>Members </b><button
                      className="walrus-login-button"
                      onClick={() => handleSignIn()}
                    >
                      {/* <img
                        alt="gsn-nav"
                        src={login}
                        style={{ width: "60px", height: "30px" }}
                      /> */}
                      LOGIN
                    </button></span>
        </div>

    </div>
  );
  const getMessage = () => {
    switch (subscriptionPosition) {
      case constants.SubscriptionPosition.ANONYMOUS:
        return renderJoin();
      case constants.SubscriptionPosition.BLOCKED:
      case constants.SubscriptionPosition.EXPIRED:
      case constants.SubscriptionPosition.UNSUBSCRIBED:
      case constants.SubscriptionPosition.CANCELLED:
      // {
      //   return (
      //     <div
      //       className="live-cam-mask-text-container"
      //       style={{
      //         fontSize: size === constants.MaskSize.SMALL ? "10px" : "18px",
      //       }}
      //     >
      //       <div className="live-cam-mask-text">Members Only Live Surf-Cam</div>
      //       <div className="live-cam-mask-text">
      //         <span className="live-cam-mask-links" onClick={handleSubscribe}>
      //           SUBSCRIBE NOW
      //         </span>{" "}
      //       </div>
      //     </div>
      //   );
      // }
      case constants.SubscriptionPosition.TRIAL:
        return <div />;
      case constants.SubscriptionPosition.MONTHLY:
        return <div />;
      case constants.SubscriptionPosition.YEARLY:
        return <div />;
      case constants.SubscriptionPosition.OVERDUE:
        // we need to send the user to accounts page here in future
        return <div />;
      default:
        return renderJoin();
    }
  };

  return getMessage();
};

export default CamMask;
