import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import "./EmailVerificationSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "redux/features/auth/authSlice";
import { autoSignIn, clearAuthError } from "redux/features/auth/authSlice";
import { getUser } from "redux/features/user/userSlice";
import { Hub } from "aws-amplify";
import { resendCode } from "redux/features/auth/authSlice";

const EmailVerificationSection = ({ data }) => {
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state?.authInfo);
  const history = useHistory(); 
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  useEffect(()=> {
    dispatch(clearAuthError());
  }, []);
  
  useEffect(() => {
    const hubListenerCancelToken = Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      if (event === "autoSignIn") {
        const user = payload.data;
        dispatch(
          autoSignIn({
            email: user?.attributes?.email,
            emailVerified: user?.attributes?.email_verified,
            userId: user?.attributes["custom:gsnUserId"],
            signedIn: true,
          })
        );
        dispatch(getUser(user?.attributes["custom:gsnUserId"]));

        history.push("/packages");
        // assign user
      } else if (event === "autoSignIn_failure") {
        // redirect to sign in page
        history.push("/sign-in");
      } else {
        console.log(event);
      }
    });

    return () => {
      hubListenerCancelToken();
    };
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(clearAuthError());
    dispatch(
      confirm({
        email: authInfo?.info?.email,
        code: data?.code,
      })
    ).unwrap().then().catch((e) => {
      console.log(e.message);
      setLoading(false);
    })
  };
  const handleClickResend = () => {
    dispatch(resendCode({ email: authInfo?.info?.email }));
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Email Verification</h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <p className="text-start form-description">
              Your 6-digit code has been sent! Please check your email, including your spam folder, if you don't see it right away
            </p>
          </Col>
        </Row>
        <br />
        {/* <Row>
          <Col className="ml-auto mr-auto" md="4">
            <div className="resend-container">
              <span>
                Almost there! We just sent you a 6-digit code. Please check your
                email then enter the code below
              </span>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="Enter your 6 digit code"
                label="code"
                type="code"
                inputRef={register("code", { required: true })}
                name="code"
                invalid={!!errors.code}
                error="This field code is required"
              />
              {authInfo?.error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {authInfo?.error?.message}
                </span>
              )}
              <Button block className="login-button">
                {authInfo.status === "pending" || loading? (
                  <Spinner size="sm" type="grow" />
                ) : (
                  `Verify ${" "}`
                )}
              </Button>
              <div className="resend-container">
                <span>
                  Didn't receive a code ?{" "}
                  <span className="resend-code" onClick={handleClickResend}>
                    RESEND
                  </span>
                </span>
              </div>
            </Form>
            {/* <div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default EmailVerificationSection;

