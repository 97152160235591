import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FooterBlack from "components/Footers/FooterBlack.js";
import HomeSections from "./home-sections/HomeSections.js";
import GoodSurfNavBar from "../components/Navbars/GoodSurfNavBar.js";
import ApiService from "services/ApiService.js";
import _ from "lodash";
import LocationFooter from "../components/Locations/LocationFooter.js";
import RecentlyVisited from "components/recentlyVisited/RecentlyVisited.js";
import Promotion from "components/GSNAds/Promotion.js";
import HomeCams from "components/HomeCams/HomeCams.js";
import { useDispatch, useSelector } from "react-redux";
import { getCameras } from "redux/features/camera/cameraSlice.js";
import { Hub } from "aws-amplify";
import { autoSignIn } from "redux/features/auth/authSlice.js";
import { getUser } from "redux/features/user/userSlice.js";
import FreeTrialPromotion from "components/GSNAds/FreeTrialPromotion.js";
import GoodSurfNavBarProd from "components/Navbars/GoodSurfNavBarProd.js";
import Premium from "components/Premium/Premium.js";

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const user = useSelector((state) => state?.authInfo?.info);

  useEffect(() => {
    dispatch(
      getCameras({
        signedIn: user?.signedIn,
      })
    );
  }, [user?.signedIn]);


  useEffect(() => {
    if (user?.userId) {
      dispatch(getUser(user?.userId));
    }
  }, [user?.userId]);
  

  useEffect(() => {
    (async function callApi() {
      try {
        const locations = await ApiService.get("locations?_limit=-1");
        setLocations(_.orderBy(locations, (item) => item.order));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  const handleSelectPost = (post) => {
    // should redirect to the post page
    history.push({
      pathname: `/post/${post.slug}`,
      state: { post },
    });
  };
  return (
    <>
      {/* <DropdownWhiteNavbar locations={locations} /> */}
      <Helmet>
        <title>Good Surf Now!</title>
        <meta property="og:title" key="og:title" content="Good Surf Now!" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.goodsurfnow.co.nz"
        />
        <meta
          property="og:description"
          key="og:description"
          content="The best surf website in Aotearoa / New Zealand. Supremely accurate video surf forecasts, forecast graphs, links to cams, wave buoys and the latest surf videos and news"
        />
        <link rel="canonical" href="https://www.goodsurfnow.co.nz/" />
        <meta
          name="description"
          content="The best surf website in Aotearoa / New Zealand. Supremely accurate video surf forecasts, forecast graphs, links to cams, wave buoys and the latest surf videos and news"
        />
      </Helmet>
      <GoodSurfNavBarProd locations={locations} />
      <div className="wrapper">
        <RecentlyVisited />
        {/* <Promotion /> */}
        {/* <FreeTrialPromotion /> */}
        {/* <HomeCams locations={_.slice(locations, 0, 9)} /> */}
        <Premium/>
        <HomeSections onSelectPost={handleSelectPost} />
        <LocationFooter locations={locations} />
        <FooterBlack />
      </div>
    </>
  );
}

export default Home;
