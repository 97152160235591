import React, { useEffect, useState } from "react";
import _ from "lodash";
import localStorageService from "services/LocalStorageService";
import { useHistory, useLocation } from "react-router";
import "./RecentlyVisited.scss";
import flag from "../../assets/recent/favourite_flag.png";
import liveCam from "../../assets/cams/live_cam_pink.png"
import { Container } from "reactstrap";

const RecentlyVisited = () => {
  const [recent, setRecent] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const recentLocations = localStorageService.getLocations();
    setRecent(recentLocations);
  }, [location?.pathname]);

  const handleCLickLocation = (loc) => {
    history.push({
      pathname: `/location/${loc.slug}`,
    });
  };
  return (
    <Container className="pl-0 pr-0">
      {recent?.length ? (
        <div className="recent-locations-container">
          {_.map(recent, (loc, index) => (
            <div key={index} className="recent-location">
              {loc.hasCam ? (
                <img
                  src={liveCam}
                  alt="live_cam"
                  style={{
                    width: "16.5px",
                    height: "10.125px",
                    // marginLeft: "5px",
                  }}
                ></img>
              ) : (
                <img
                  src={flag}
                  alt="flag"
                  style={{ width: "12px", height: "12px" }}
                ></img>
              )}

              <div
                className="recent-location-text"
                onClick={() => handleCLickLocation(loc)}
              >
                {loc.label}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="recent-locations-container-empty" />
      )}
    </Container>
  );
};

export default RecentlyVisited;
