import React, { useEffect, useState } from "react";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import logoDesktop from "../../assets/logo/gsn_logo_desktop.png";
import logoMobile from "../../assets/logo/gsn_logo_mobile.png";
// import navIcon from '../../assets/nav/nav_icon.png';
import navIcon from "../../assets/nav/forecast_burger_icon.png";
import avatar from "../../assets/nav/seal_icon.png";
import navIconMobile from "../../assets/nav/nav_icon.png";
import login from "../../assets/nav/login.png";
import join from "../../assets/nav/join.png";
// import { FaBars } from 'react-icons/fa';
import LocationFooter from "../Locations/LocationFooter";
import "./GoodSurfNavBar.scss";

// reactstrap components
import {
  Collapse,
  Col,
  Row,
  Navbar,
  Container,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "redux/features/auth/authSlice";
import { mobileModel } from "react-device-detect";
import { getSubscriptionPosition } from "tools/utills";
import constants from "../../constants";

const GoodSurfNavBarProd = ({ locations }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [subscription, setSubscription] = useState();
  const user = useSelector((state) => state?.authInfo?.info);
  const uss = useSelector((state) => state?.user?.info);

  useEffect(() => {
    const sub = getSubscriptionPosition(uss);
    setSubscription(sub);
  }, [uss]);

  const handleClickLogo = () => {
    history.push({
      pathname: "/",
    });
  };

  const handleToggle = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const handleClickFreeTrial = () => {
    history.push({
      pathname: "/sign-up",
    });
  };

  const handleClickLogin = () => {
    history.push({
      pathname: "/sign-in",
    });
  };
  const handleSignIn = () => {
    console.log("handle sign in ");
    history.push({
      pathname: "/sign-in",
    });
  };
  const handleClickMyAccount = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/account",
    });
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(signOut()).unwrap().then(() => {
      history.replace({
        pathname: "/",
      });
    })

  };

  const handleGetPremium = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/packages",
    });
  };
  return (
    <>
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container className="pl-0 pr-0">
          <Row className="m-0 p-0 navbar-translate good-surf-nav-bar">
            <Col xs="4" className="p-0 d-none d-sm-block">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIconMobile}
                  style={{ width: "30px", height: "25px" }}
                />
              </div>
            </Col>
            <Col xs="5" className="p-0 d-block d-sm-none">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIconMobile}
                  style={{ width: "30px", height: "25px" }}
                />
              </div>
            </Col>
            <Col
              xs="4"
              className="pl-0 pr-0 ml-0 mr-0 desktop-icon d-none d-sm-flex"
            >
              <img
                src={logoDesktop}
                alt="Good Surf Now"
                style={{ width: "146px", height: "30px" }}
                onClick={handleClickLogo}
              ></img>
            </Col>
            <Col
              xs="2"
              className="pl-0 pr-0 ml-0 mr-0 desktop-icon d-flex d-sm-none"
            >
              <img
                src={logoMobile}
                alt="Good Surf Now"
                style={{ width: "45px", height: "45px", margin: "-15px -15px -15px -15px" }}
                onClick={handleClickLogo}
              ></img>
              {/* <img
                src={logoDesktop}
                alt="Good Surf Now"
                style={{ width: "146px", height: "30px" }}
                onClick={handleClickLogo}
              ></img> */}
            </Col>
            <Col
              xs="4"
              className="pl-0 pr-0 ml-0 mr-0 desktop-dropdown-icon d-none d-sm-block"
            >
              <div className="gsn-nav-icon-button">
                {user?.signedIn ? (
                  <Dropdown isOpen={isOpenMenu} inNavbar>
                    <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      // href="http://example.com"
                      id="navbarDropdownMenuLink"
                      nav
                      onClick={() => setIsOpenMenu(!isOpenMenu)}
                    // direction="up"
                    >
                      {/* <p className="avatar-dropdown-text">CHUUURRR </p> */}
                      <img
                        alt="gsn-nav"
                        className="location-img"
                        src={avatar}
                        style={{ width: "41px", height: "40px" }}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      aria-labelledby="navbarDropdownMenuLink"
                      className="avatar-dropdown-menu"
                      right={true}
                    >
                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleClickMyAccount}
                      >
                        My account
                      </DropdownItem>
                      {/* {subscription ===
                        constants.SubscriptionPosition.EXPIRED ||
                      subscription === constants.SubscriptionPosition.DAILY ||
                      subscription === constants.SubscriptionPosition.MONTHLY ||
                      subscription === constants.SubscriptionPosition.TRIAL ||
                      subscription === constants.SubscriptionPosition.YEARLY ? (
                        <div />
                      ) : (
                        <DropdownItem
                          className="avatar-dropdown-premium-item"
                          href="#pablo"
                          onClick={handleGetPremium}
                          text
                          style={{ fontSize: "16px", marginTop: "-20px" }}
                        >
                          <div className="my-acc-dropdown-text">
                            Free version
                          </div>
                          Get Premium
                        </DropdownItem>
                      )} */}

                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <div
                    className="gsn-auth-buttons"
                  >
                    <button
                      className="login-button-nav"
                      onClick={() => handleClickLogin()}
                    >
                      <img
                        alt="gsn-nav"
                        src={login}
                        style={{ width: "60px", height: "30px" }}
                      />
                    </button>
                    <button
                      className="login-button-nav"
                      onClick={() => handleClickFreeTrial()}
                    >
                      <img

                        alt="gsn-nav"
                        src={join}
                        style={{ width: "60px", height: "30px" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs="5"
              className="pl-0 pr-0 ml-0 mr-0 desktop-dropdown-icon d-block d-sm-none"
            >
              <div className="gsn-nav-icon-button">
                {user?.signedIn ? (
                  <Dropdown isOpen={isOpenMenu} inNavbar>
                    <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      // href="http://example.com"
                      id="navbarDropdownMenuLink"
                      nav
                      onClick={() => setIsOpenMenu(!isOpenMenu)}
                    // direction="up"
                    >
                      {/* <p className="avatar-dropdown-text">CHUUURRR </p> */}
                      <img
                        alt="gsn-nav"
                        className="location-img"
                        src={avatar}
                        style={{ width: "41px", height: "40px" }}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      aria-labelledby="navbarDropdownMenuLink"
                      className="avatar-dropdown-menu"
                      right={true}
                    >
                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleClickMyAccount}
                      >
                        My account
                        {/* <div className="my-acc-dropdown-text">Free version</div> */}
                      </DropdownItem>
                      {/* <DropdownItem
                        className="avatar-dropdown-premium-item"
                        href="#pablo"
                        onClick={handleGetPremium}
                        text
                        style={{ fontSize: "16px", marginTop: "-20px" }}
                      >
                        Get Premium
                      </DropdownItem> */}
                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <div className="gsn-auth-buttons">
                    <button
                      className="login-button-nav"
                      onClick={() => handleClickLogin()}
                    >
                      <img
                        alt="gsn-nav"
                        src={login}
                        style={{ width: "60px", height: "30px" }}
                      />
                    </button>
                    <button
                      className="login-button-nav"
                      onClick={() => handleClickFreeTrial()}
                    >
                      <img

                        alt="gsn-nav"
                        src={join}
                        style={{ width: "60px", height: "30px" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Collapse isOpen={collapseOpen} navbar>
            <LocationFooter
              locations={locations}
              navbar
              onClose={handleToggle}
            />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default GoodSurfNavBarProd;
