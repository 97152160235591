import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../../services/AuthService";
import userService from "services/UserService";
import { getUser, invalidateUser } from "../user/userSlice";
import {invalidatePackages} from "../subscription/subscriptionSlice";
import constants from "../../../constants";

export const signIn = createAsyncThunk(
  "authInfo/signIn",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const authRes = await authService.login(params?.email, params?.password);
      await dispatch(getUser(authRes?.userId)).unwrap();
      return {
        email: authRes?.email,
        signedIn: authRes?.signedIn,
        emailVerified: authRes?.emailVerified,
        userId: authRes?.userId,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
        email:
          err.code === constants.AmplifyExceptions.USER_NOT_CONFIRMED_EXCEPTION
            ? params?.email
            : null,
      });
    }
  }
);

export const signOut = createAsyncThunk(
  "authInfo/signOut",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      await authService.logOut();
      dispatch(invalidateUser());
      dispatch(invalidatePackages());
      return {
        email: null,
        signedIn: false,
        emailVerified: false,
        userId: null,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const receiveCurrentUser = createAsyncThunk(
  "authInfo/receiveCurrentUser",
  async (params, { rejectWithValue }) => {
    try {
      const results = await authService.getCurrentUserAmplify();
      return {
        email: results?.email,
        signedIn: results?.signedIn,
        emailVerified: results?.emailVerified,
      };
    } catch (err) {
      // only logging the error
      // we don't do anything here
      console.log(err?.message);
      return {
        email: null,
        signedIn: false,
        emailVerified: false,
      };
    }
  }
);

export const signUp = createAsyncThunk(
  "authInfo/signUp",
  async (params, { rejectWithValue }) => {
    try {
      const authRes = await userService.register(
        params?.email,
        params?.password,
        params?.firstName,
        params?.lastName
      );
      return {
        email: authRes?.username,
        userId: null,
        emailVerified: false,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "authInfo/forgotPassword",
  async (params, { rejectWithValue }) => {
    try {
      const authRes = await userService.forgotPassword(params?.email);
      return {
        email: params?.email,
        pwdResetRequested: true,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authInfo/resetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const authRes = await userService.forgotPasswordSubmit(
        params?.email,
        params?.code,
        params?.password
      );
      return {
        pwdResetRequested: false,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const confirm = createAsyncThunk(
  "authInfo/confirm",
  async (params, { rejectWithValue }) => {
    try {
      await userService.confirm(params?.email, params?.code);
      return {
        emailVerified: true,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const resendCode = createAsyncThunk(
  "authInfo/resendCode",
  async (params, { rejectWithValue }) => {
    try {
      await userService.resendConfirmationCode(params?.email);
      return {
        codeResent: true,
      };
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

const initialState = {
  info: {
    email: null,
    loginAttempts: 0,
    userId: null,
    emailVerified: false,
    pwdResetRequested: false,
  },
  error: null,
  state: "idle",
};

const authSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {
    incrementLoginAttemptsByOne: (state) => {
      state.info.loginAttempts += 1;
    },
    incrementLoginAttemptsByNumber: (state, action) => {
      state.info.loginAttempts += action.payload;
    },
    clearAuthError: (state) => {
      state.error = null
    },
    clearPwdRestReq: (state) => {
      state.info.pwdResetRequested = false
    },
    autoSignIn: (state, action) => {
      state.info = {
        ...state.info,
        email: action?.payload?.email,
        signedIn: action?.payload?.signedIn,
        emailVerified: action?.payload?.emailVerified,
        userId: action?.payload?.userId,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.info.email = action.payload?.email;
      state.info.signedIn = action.payload?.signedIn;
      state.info.emailVerified = action.payload.emailVerified;
      state.info.userId = action.payload.userId;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(signIn.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.error = {
        code: action.payload.code,
        message: action.payload.message,
      };
      state.info.email = action.payload?.email; // retain the email even if sign in fails
      state.status = "failed";
    });
    builder.addCase(signOut.fulfilled, (state, action) => {
      state.info.email = action.payload?.email;
      state.info.signedIn = action.payload?.signedIn;
      state.info.emailVerified = action.payload.emailVerified;
      state.info.userId = action.payload.userId;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(signOut.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    });
    builder.addCase(receiveCurrentUser.fulfilled, (state, action) => {
      state.info.email = action.payload?.email;
      state.info.signedIn = action.payload?.signedIn;
      state.info.emailVerified = action.payload.emailVerified;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(receiveCurrentUser.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(receiveCurrentUser.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.info = action.payload;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(signUp.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    });
    builder.addCase(confirm.fulfilled, (state, action) => {
      state.info.emailVerified = action.payload.emailVerified;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(confirm.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(confirm.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
      state.emailVerified = false;
    });
    builder.addCase(resendCode.fulfilled, (state, action) => {
      state.info.codeResent = action.payload.codeResent;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(resendCode.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(resendCode.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
      state.codeResent = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.info.pwdResetRequested = action.payload.pwdResetRequested;
      state.info.email = action.payload.email;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.info.pwdResetRequested = action.payload.pwdResetRequested;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    });
  },
});

export const selectAuthInfo = (state) => state.authInfo.info;

export const {
  incrementLoginAttemptsByNumber,
  incrementLoginAttemptsByOne,
  clearAuthError,
  clearPwdRestReq,
  autoSignIn,
} = authSlice.actions;

export default authSlice.reducer;
