import React, { useEffect, useState } from "react";
import FooterBlack from "components/Footers/FooterBlack.js";
import LocationSections from "./location-sections/LocationSections";
// import DropdownWhiteNavbar from 'components/Navbars/DropdownWhiteNavbar.js';
import ApiService from "services/ApiService.js";
import _ from "lodash";
import LocationFooter from "../components/Locations/LocationFooter.js";
import RecentlyVisited from "components/recentlyVisited/RecentlyVisited";
import GoodSurfNavBarProd from "components/Navbars/GoodSurfNavBarProd";

function Location({ location }) {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    (async function callApi() {
      const locations = await ApiService.get("locations?_limit=-1");
      setLocations(_.orderBy(locations, (item) => item.order));
    })();
  }, []);
  useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  const parts = location && location.pathname && location.pathname.split("/");
  return (
    <>
      <GoodSurfNavBarProd locations={locations} />
      <div className="wrapper">
        <RecentlyVisited />
        <LocationSections
          slug={parts && parts.length && parts.length > 2 && parts[2]}
        />
        <LocationFooter locations={locations} />
        <FooterBlack />
      </div>
    </>
  );
}

export default Location;
