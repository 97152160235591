import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, FormFeedback } from "reactstrap";
import "./ForgotPasswordSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, clearAuthError } from "redux/features/auth/authSlice";

const ForgotPasswordSection = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authInfo?.info);
  const error = useSelector((state) => state?.authInfo?.error);
  const history = useHistory();
  useEffect(()=> {
    dispatch(clearAuthError());
  }, []);
  useEffect(() => {
    if (user?.pwdResetRequested) {
      history.push("/reset-password");
    }
  }, [user?.pwdResetRequested]);

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  const onSubmit = (data) => {
    // console.log(data);
    dispatch(clearAuthError());
    dispatch(forgotPassword({ email: data?.email }));
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Forgot Password</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="email"
                label="email"
                type="email"
                inputRef={register("email", { required: true })}
                name="email"
                invalid={!!errors.email}
                error="This field email is required"
              />
              {error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {error?.message}
                </span>
              )}
              <Button block className="login-button">
                Submit{" "}
              </Button>
            </Form>
            <div className="extra-links-forgot">
              <Button
                className="create-account"
                color="neutral"
                tag={Link}
                to="/sign-in"
              >
                go to sign in
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ForgotPasswordSection;
