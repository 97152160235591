import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

const TextInput = ({
  label,
  placeholder,
  type,
  inputRef,
  name,
  invalid,
  error,
  isPassword, // New prop to determine if the field is a password field
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { ref, ...inputProps } = inputRef || { ref: null };

  // Determine the input type (text/password)
  const inputType = isPassword ? (showPassword ? "text" : "password") : type;

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <InputGroup>
        <Input
          placeholder={placeholder}
          type={inputType}
          innerRef={ref}
          name={name}
          {...inputProps}
          {...props}
          invalid={invalid}
        />
        {isPassword && (
          <InputGroupText
            onClick={() => setShowPassword(!showPassword)}
            style={{
              cursor: "pointer",
              display: "flex", // Use Flexbox to center content
              justifyContent: "center", // Horizontally center
              alignItems: "center", // Vertically center
              padding: "0.5rem", // Optional: Adjust padding to make the click area larger
              width: "40px",
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </InputGroupText>
        )}
        <FormFeedback>{error ? error : "something went wrong!"}</FormFeedback>
      </InputGroup>
      
    </FormGroup>
  );
};

export default TextInput;
