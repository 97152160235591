import React, { useEffect, useState } from 'react';
import premium from '../../../assets/logo/gsn_premium_small.png';
import './OGPackage.scss'
import { useHistory } from 'react-router-dom';
import { selectSubscription } from 'redux/features/subscription/subscriptionSlice';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import { getPackages } from 'redux/features/subscription/subscriptionSlice';
import localStorageService from 'services/LocalStorageService';

const OGPackage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [ogPackage, setOGPackage] = useState(null);


  useEffect(() => {
    setTimeout(() => {
      dispatch(getPackages())
        .unwrap()
        .then((resp) => {
          console.log(resp)
          if (resp?.length && resp?.length === 1) {
            setOGPackage(resp[0])
            setIsOpen(true)
          }
        })
        .catch((err) => {
          console.log(err?.message)
          setIsOpen(true)
        })
    }, 5000)
  }, []);

  const handleSelectPackage = (packageId) => {
    if (packageId) {
      dispatch(selectSubscription(packageId));
      history.push("/checkout");
    } else {
      history.push("/sign-up");
    }
  };

  const handleShowModal = () => {
    localStorageService.setPremiumMessageShowedAt();
    setIsOpen(!isOpen);
  };
  const renderModal = () => {
    return (
      <Modal isOpen={isOpen} toggle={handleShowModal} className="og-modal">
        <ModalHeader toggle={handleShowModal}></ModalHeader>
        <div
          onClick={() => handleSelectPackage(ogPackage?.id)}
        >
          <div className="fly-og-promotion-container">
            <img
              src={premium}
              alt="live_cam"
              className="fly-gsn-premium-image"
            // style={{ width: "30px", height: "22.2px" }}
            />
            <div className="fly-og-hookup-big">
              The O.G. HOOKUP
            </div>
            {/* <div className="fly-be-first-text">
              Be one of the first paid subscriber to get
            </div> */}
            <div className="fly-fifty-percent">
              <b>40% OFF</b> <i>FOR LIFE*</i>
            </div>
            <div className="fly-use-code">Use code <span className="fly-life-forty">LIFE40</span> at checkout</div>
            <br/>
          </div>

          <br />
          <div className="fly-premium-highlights">
            <ul>
              <li>14 + Kiwi owned cams</li>
              <li>144 + High resolution spot forecasts</li>
              <li>Links to all local free cams and wave buoys</li>
              <li>Help us support local mahi, like Aroha Surf Club</li>
            </ul>
          </div>
          {ogPackage ? <div className="fly-premium-prices">
            <span className="fly-premium-cross">NZ${ogPackage?.stripe?.price?.unit_amount / 100}/year</span><span><b>NZ${ogPackage?.stripe?.price?.unit_amount * 0.006}/year ($3/month)</b></span>
          </div> : <div className="fly-premium-prices">
            <span className="fly-premium-cross">NZ$60/year</span><span><b>NZ$36/year ($3/month)</b></span>
          </div>}
          <div className="fly-premium-price-container">
            <div
              className="fly-package-price-button"
              style={{
                backgroundColor: "#231F20",
              }}
            >
              SUBSCRIBE
            </div>
          </div>
          <br />
          <div className="fly-tnc-apply">*Terms and conditions apply</div>
          <div className="fly-cancel-anytime">14-day trial. Cancel anytime.</div>
          <br />
          {/* <div className="fly-auto-renewal">{`Cancel auto-renewal anytime in account settings`}</div> */}
          <br />
        </div>
      </Modal>
    );
  };

  return renderModal()
}

export default OGPackage;