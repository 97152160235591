import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import "./PackagesSection.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubscription,
  getPackages,
  // clearSubscriptionError,
} from "redux/features/subscription/subscriptionSlice";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants, { SubscriptionStatus } from "../../constants";
import shaka from "../../assets/icons/shaka.png";
import premium from "../../assets/logo/gsn_premium_small.png"
import CenterSpinner from "components/Spinner/CenterSpinner";
import moment from "moment";

const PackagesSection = () => {
  const info = useSelector((state) => state.subscription.info);
  const userInfo = useSelector((state) => state?.user?.info);
  const [monthlyPackage, setMonthlyPackage] = useState(null);
  const today = moment();
  const cutOffDay = moment('31/01/2025', 'DD/MM/YYYY'); // Ensure you specify the format
  const days = cutOffDay.diff(today, 'days'); 
  // const [errorVisible, setErrorVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  // // Trigger toast if there's an error
  // useEffect(() => {
  //   if (error && !errorVisible) {
  //     toast.warn(error?.message, {
  //       position: "top-right",
  //       autoClose: 5000, // Close after 5 seconds
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "light",
  //       transition: "Bounce",
  //       onClose: handleClickClose, // Close handler for both timeout and manual close
  //     });
  //     setErrorVisible(true);
  //   }
  // }, [error, errorVisible]);

  useEffect(() => {
    if (info?.packages?.length) {
      const monthly = _.find(
        info?.packages,
        (pkg) => pkg?.stripe?.price?.recurring?.interval === constants.BillingPeriodUnit.MONTH
      );
      setMonthlyPackage(monthly);
    }
  }, [info?.packages]);

  // Fetch packages on mount
  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  // Handle package selection
  const handleSelectPackage = (packageId) => {
    dispatch(selectSubscription(packageId));
    history.push("/checkout");
  };

  // Navigate to homepage on skip
  const handleClickSkipNow = () => {
    history.push("/");
  };

  // Handle closing the toast and clearing the error
  // const handleClickClose = () => {
  //   setErrorVisible(false); // Reset error visibility state
  //   dispatch(clearSubscriptionError()); // Clear error from Redux store
  // };
  const renderOGPackage = (pac, index) => {
    return (<div className="d-flex justify-content-center align-items-center">
      <Col className="p-0 m-0 g-0" xs={12} md={8} key={index}>
        <div
          className="plan"
          onClick={() => handleSelectPackage(pac?.id)}
        >
          <div className="og-promotion-container">
              <img
                src={premium}
                alt="live_cam"
                className="gsn-premium-image"
                // style={{ width: "252px", height: "66.6px" }}
              />
            <div className="og-hookup-big">
              The O.G. HOOKUP
            </div>
            {/* <div className="be-first-text">
              Be one of the first paid subscriber to get
            </div> */}
            <div className="fifty-percent">
              <b>40% OFF</b> <i>FOR LIFE*</i>
            </div>
            <div className="use-code">Use code <span className="life-forty">LIFE40</span> at checkout</div>
          </div>

          <br />
          <div className="premium-highlights">
            <ul>
              <li>14 + Kiwi owned cams</li>
              <li>144 + High resolution spot forecasts</li>
              {/* <li>Swell alert emails for your region</li> */}
              <li>Links to all local free cams and wave buoys</li>
              <li>Help us support local mahi, like Aroha Surf Club</li>
            </ul>
          </div>
          <div className="premium-prices">
            <span className="premium-cross">NZ${pac?.stripe?.price?.unit_amount / 100}/year</span><span><b>NZ${pac?.stripe?.price?.unit_amount * 0.006}/year ($3/month)</b></span>
          </div>
          <div className="premium-price-container">
            <div
              className="package-price-button"
              style={{
                backgroundColor: "#231F20",
              }}
            >
              SUBSCRIBE
            </div>
          </div>
          <br />
          <div className="tnc-apply">*Terms and conditions apply</div>
          <div className="cancel-anytime">14-day trial. Cancel anytime.</div>
          <br />
          {/* <div className="auto-renewal">{`Cancel auto-renewal anytime in account settings`}</div> */}
          <br />
        </div>
      </Col></div>)
  }
  const renderGenericPackage = (pac, index) => {
    return (<Col className="p-0 m-0 g-0" xs={12} md={6} key={index}>
      <div
        className={`plan ${pac?.promo
          ? "plan-yearly"
          : "plan-monthly"
          }`}
        onClick={() => handleSelectPackage(pac?.id)}
      >
        {pac?.promo && monthlyPackage
          ? (
            <div className="yearly-promotion-container">
              <img
                src={shaka}
                alt="live_cam"
                style={{ width: "30px", height: "22.2px" }}
              />
              <div className="yearly-save-text">
                <span className="save-value">
                  SAVE{" "}
                  {(
                    ((monthlyPackage?.stripe?.price?.unit_amount *
                      12 -
                      pac?.stripe?.price?.unit_amount) /
                      (monthlyPackage?.stripe?.price?.unit_amount *
                        12)) *
                    100
                  ).toFixed(0)}
                  %
                </span>{" "}
                CHUUUURRR!!!
              </div>
            </div>
          ) : (
            <div className="monthly-promotion-container" />
          )}

        <br />
        <h3>{pac.name}</h3>
        <p className="free-trial-text">
          {pac.freeTrialDays && userInfo?.status !== SubscriptionStatus.CANCELLED
            ? `${pac.freeTrialDays} - days free trial`
            : "no trial"}
        </p>
        <div className="package-price-container">
          <div
            className="package-price-button"
            style={{
              backgroundColor:
                pac?.promo
                  ? "#fd2dbf"
                  : "#231F20",
            }}
          >{pac?.promo ? `$${(
            pac?.stripe?.price?.unit_amount /
            (100 * 12)
          ).toFixed(2)}/ month` : `$${(
            pac?.stripe?.price?.unit_amount /
            (100)
          ).toFixed(2)}/ month`}</div>
        </div>
        <br />
        {pac?.promo ? (
          <div className="billing-cycle">{`$${(
            pac?.stripe?.price?.unit_amount / 100
          ).toFixed(2)} NZD billed once a ${pac?.stripe?.price?.recurring?.interval
            }`}</div>
        ) : (
          <div className="billing-cycle">{`$${(
            pac?.stripe?.price?.unit_amount / 100
          ).toFixed(2)} NZD billed monthly`}</div>
        )}
        <br />
        <div className="auto-renewal">{`Cancel auto-renewal anytime in account settings`}</div>
        <br />
      </div>
    </Col>)
  }
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000} // Closes after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start packages-title">Sign up complete!</h1>
          </Col>
        </Row>
        <Row>
          {info?.packages?.length > 1 ? <Col className="ml-auto mr-auto" md="8">
            <p className="text-start packages-description">
              Almost there!!! Please choose a payment option
            </p>
          </Col> : info?.packages?.length === 1 ?
            <Col className="ml-auto mr-auto" md="12" lg="10" xl="7">
              <Row className="p-0 m-0">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <Col className="p-0 m-0 g-0" xs={12} md={8}>
                    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                      <li className="highlights-list">
                        <div className="highlight-text"><div className="highlight-tick">✔︎</div> <div><b>{days} days of FREE</b> cam access remaining. <a href="/packages">Subscribe Now</a> to get <b>40% off for life.</b></div></div>
                      </li>
                      <li className="highlights-list">
                      <div className="highlight-text"><div className="highlight-tick">✔︎</div><div>Weekend Surf Outlook email every Thurs.</div></div>
                      </li>
                    </ul>
                  </Col></div>
              </Row></Col> : <div/>}
        </Row>
        {info?.packages?.length ? (
          <Row className="mt-4">
            <Col className="ml-auto mr-auto" md="12" lg="10" xl="7">
              <Row className="p-0 m-0 mb-2">
                {_.map(
                  _.orderBy(info?.packages, (p) => p.stripe?.price?.unit_amount, "desc"),
                  (pac, index) => info?.packages?.length > 1 ? renderGenericPackage(pac, index) : renderOGPackage(pac, index)
                )}
              </Row>
            </Col>
          </Row>
        ) : (
          <div className="packages-fixed-height">
            <CenterSpinner type="border" size="lg" />
          </div>
        )}
        {/* <Row>
          <Col className="ml-auto mr-auto" md="4">
            <div className="generic-account-ad-slot-container generic-ad-slot-center">
              <img
                src="https://gsn-photos.s3.ap-southeast-2.amazonaws.com/rectangle_maz_d0786e2f31.jpg"
                className="generic-ad-slot"
              />
            </div>
          </Col>
        </Row> */}
        <div className="skip-now-container" onClick={handleClickSkipNow}>
          <span className="skip-now-text">skip for now</span>
        </div>
      </Container>
    </div>
  );
};
export default PackagesSection;
