module.exports = {
  Coasts: {
    NI_NORTH_EAST_COAST: "niNorthEastCoast",
    NI_SOUTH_EAST_COAST: "niSouthEastCoast",
    NI_WEST_COAST: "niWestCoast",
    SI_EAST_COAST: "siEastCoast",
    SI_WEST_COAST: "siWestCoast",
  },
  DirectionLabels: {
    NORTH: "N",
    NORTH_EAST: "NE",
    EAST: "E",
    SOUTH_EAST: "SE",
    SOUTH: "S",
    SOUTH_WEST: "SW",
    WEST: "W",
    NORTH_WEST: "NW",
  },
  ResourceTypes: {
    CAM: "cam",
    BUOY: "buoy",
  },
  PostAdSlots: {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
  },
  HomeAdSlots: {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
  },
  SubscriptionPosition: {
    ANONYMOUS: "anonymous",
    UNSUBSCRIBED: "unsubscribed",
    TRIAL: "trial",
    MONTHLY: "monthly",
    YEARLY: "yearly",
    EXPIRED: "expired",
    UNKNOWN: "unknown",
    BLOCKED: "blocked",
    DAILY: "daily",
    OVERDUE: "overdue",
    CANCELLED: 'cancelled'
  },
  SubscriptionStatus: {
    ACTIVE: "active",
    INACTIVE: "inactive",
    OVERDUE: "overdue",
    CANCELLED: 'cancelled'
  },
  BillingPeriodUnit: {
    MONTH: "month",
    YEAR: "year",
    DAY: "day"
  },
  MaskSize: {
    SMALL: "small",
    LARGE: "large",
  },
  AmplifyExceptions: {
    USER_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  }
};
