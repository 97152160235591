import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, Spinner } from "reactstrap";
import "./ResetPasswordSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/features/auth/authSlice";
import { forgotPassword } from "redux/features/auth/authSlice";

const ResetPasswordSection = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authInfo?.info);
  const authInfo = useSelector((state) => state?.authInfo);
  const error = useSelector((state) => state?.authInfo?.error);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  useEffect(() => {
    if (user?.email && !user?.pwdResetRequested) {
      history.push("/sign-in");
    }
  }, [user?.email, user?.pwdResetRequested]);

  const onSubmit = (data) => {
    dispatch(
      resetPassword({
        email: user?.email,
        code: data?.code,
        password: data?.password,
      })
    );
  };

  const handleClickResend = () => {
    dispatch(forgotPassword({ email: user?.email }));
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Reset Password</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <p className="text-start form-description" style={{ "display": "inline" }}>
              We've sent a single-use code to your email, please enter it
              below. If it doesn't arrive check your spam or{" "}
              <span className="resend-code" onClick={handleClickResend}>
                resend
              </span>
            </p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="code"
                label="single-use code"
                type="code"
                inputRef={register("code", { required: true })}
                name="code"
                invalid={!!errors.code}
                error="This field code is required"
              />
              <TextInput
                placeholder="password"
                label="new password"
                type="password"
                inputRef={register("password", { required: true })}
                name="password"
                invalid={!!errors.password}
                error="This field password is required"
                isPassword={true}
              />
              <TextInput
                placeholder="confirm password"
                label="confirm password"
                type="password"
                inputRef={register("confirmPassword", { required: true })}
                name="confirmPassword"
                invalid={!!errors.confirmPassword}
                error="This field confirm password is required"
                isPassword={true}
              />
              {error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {error?.message}
                </span>
              )}
              <Button block className="login-button">
                {authInfo.status === "pending" ? (
                  <Spinner size="sm" type="grow" />
                ) : (
                  `Reset`
                )}
              </Button>
            </Form>
            <div className="extra-links-reset">
              <Button
                className="create-account"
                color="neutral"
                tag={Link}
                to="/sign-in"
              >
                go to sign in
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ResetPasswordSection;
