import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import amplifyApiService from "services/AmplifyApiService";
import _ from "lodash";

export const getCameras = createAsyncThunk(
  "camera/getCameras",
  async (params, { rejectWithValue }) => {
    try {
      const results = await amplifyApiService.get(
        params?.signedIn ? "cams" : "demo/cams"
      );
      if (results?.data) {
        return results.data.cams;
      } else {
        return [];
      }
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

const initialState = {
  info: {
    cams: [],
    selectedCamera: null,
  },
  error: null,
  state: "idle",
};

const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    selectCamera: (state, action) => {
      state.info.selectedCamera = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCameras.fulfilled, (state, action) => {
      state.info.cams = action.payload;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(getCameras.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getCameras.rejected, (state, action) => {
      state.error = action.payload;
      state.info.cams = [];
      state.info.selectedCamera = null;
      state.status = "failed";
    });
  },
});

export const selectCameraInfo = (state) => state.camera.info;
export const { selectCamera } = cameraSlice.actions;
export default cameraSlice.reducer;
