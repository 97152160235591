import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import _ from "lodash";
import VideoPlayer from "../../components/video/VideoPlayer";
import ForcastCharts from "../../components/charts/ForcastCharts";
import "./LocationSections.scss";
import ApiService from "../../services/ApiService";
// import WeatherMapWrapper from "../../components/WindyMap/WeatherMapWrapper";
import CamContainer from "components/Cams/CamContainer";
import Description from "components/Description/Description";
import PostCards from "components/PostCards/PostCards";
import SocialLinks from "components/SocialLinks/SocialLinks";
import LocationAdSlotsTop from "components/Ads/LocationAdSlotsTop";
import LocationAdSlotBody from "components/Ads/LocationAdSlotBody";
import WindyWidget from "components/WindyMap/WindyWidget";
import { Helmet } from "react-helmet-async";
import localStorageService from "services/LocalStorageService";
import { useDispatch, useSelector } from "react-redux";
import GSNCam from "components/GSNCam/GSNCam";
import InstallPWABanner from "components/InstallPWA/InstallPWABanner";
import { getCameras } from "redux/features/camera/cameraSlice";
import OGPackage from "views/packages-sections/package/OGPackage";
import moment from "moment";

// core components

const LocationSections = ({ slug }) => {
  const [location, setLocation] = useState(null);
  const [coast, setCoast] = useState(null);
  const [posts, setPosts] = useState(null);
  const sub = useSelector((state) => state?.user?.info?.user_subscription) ?? null;
  const status = useSelector((state) => state?.user?.info?.status) ?? null;
  const user = useSelector((state) => state?.authInfo?.info);
  const camInfo = useSelector((state) => state?.camera?.info);
  const [selectedCam, setSelectedCam] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [displayPremium, setDisplayPremium] = useState(false);

  const memoizedCams = useMemo(
    () => camInfo?.cams || [],
    [camInfo?.cams?.length, camInfo?.cams]
  );

  const [prevCams, setPrevCams] = useState(null);

  // Utility function for deep comparison
  const deepCompareCams = (prevCams, nextCams) => {
    if (prevCams?.length !== nextCams?.length) {
      return false; // If array length changes (new cam added or removed)
    }

    return prevCams?.every((prevCam, index) => {
      const nextCam = nextCams[index];
      // return JSON.stringify(prevCam) === JSON.stringify(nextCam); // Compare each cam deeply
      return prevCam?.name === nextCam?.name && prevCam?.link === nextCam?.link;
    });
  };

  useEffect(()=> {
    if (location) {
      const cam = _.find(memoizedCams, (c) => c.location === location?.id);
      setSelectedCam(cam);
    } else {
      dispatch(
        getCameras({
          signedIn: user?.signedIn,
        })
      )
        .unwrap()
        .then((resp) => {
          const cam = _.find(resp, (r) => r.location === location.id);
          setSelectedCam(cam);
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [location])

  useEffect(() => {
    if (!prevCams || !deepCompareCams(prevCams, memoizedCams)) {
      setPrevCams(memoizedCams); // Store the current cams array for future comparison

      if (memoizedCams?.length) {
        const cam = _.find(memoizedCams, (c) => c.location === location?.id);
        setSelectedCam(cam);
      } else {
        dispatch(
          getCameras({
            signedIn: user?.signedIn,
          })
        )
          .unwrap()
          .then((resp) => {
            const cam = _.find(resp, (r) => r.location === location.id);
            setSelectedCam(cam);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  }, [memoizedCams]); // Effect depends on location and memoizedCams


  useEffect(() => {
    (async function callApi() {
      const loc = await ApiService.get(`locations?slug_eq=${slug}`);
      if (loc && loc.length) {
        const cst = await ApiService.get(`coasts?name=${loc[0].coast}`);
        setLocation(loc[0]);
        // set recently visited
        localStorageService.setLocation(loc[0]);
        if (cst && cst.length) {
          setCoast(cst[0]);
        }
      } else {
        localStorageService.removeLocation(slug);
      }
      const posts = await ApiService.get(
        `posts?_limit=${3}&_sort=id:DESC&zone_eq=generic`
      );
      if (posts && posts.length) {
        setPosts(posts);
      }
    })();
  }, [slug]);

  useEffect(() => {
    const date = localStorageService.getPremiumMessageShowedAt();
    if (date) {
      const shouldDisplay = moment().isSame(moment(date), 'day');
      setDisplayPremium(!shouldDisplay);
    } else {
      setDisplayPremium(true);
    }

  }, []);


  const handleSelectPost = (post) => {
    // should redirect to the post page
    history.push({
      pathname: `/post/${post.slug}`,
      state: { post },
    });
  };

  const renderArialImage = () => {
    return location.image && location.image.length ? (
      <div className="location-image">
        <img
          alt={location.name}
          className="location-img"
          src={location.image[0].url}
        />
      </div>
    ) : (
      <span />
    );
  };

  const renderCam = () => {
    return (
      <div className="premium-video-container">
        <GSNCam location={location} user={user} cam={selectedCam} />
      </div>
    );
  };
  return (
    <div>
      {location ? (
        <>
          <Helmet>
            <title>{location.name} | Good Surf Now!</title>
            <meta
              property="og:title"
              key="og:title"
              content={`${location.name} | Good Surf Now!`}
            />
            <meta property="og:type" key="og:type" content="website" />
            <meta
              property="og:url"
              key="og:url"
              content={`https://www.goodsurfnow.co.nz/location/${slug}`}
            />
            <meta
              property="og:description"
              key="og:description"
              content={_.truncate(location.description, { length: 169 })}
            />
            <link
              rel="canonical"
              href={`https://www.goodsurfnow.co.nz/location/${slug}/`}
            />
            <meta
              name="description"
              content={_.truncate(location.description, { length: 169 })}
            />
            <meta
              property="og:image"
              key="og:image"
              content={location.image[0].url}
            />
          </Helmet>
          {location.banner ? (
            <div
              className={`page-header page-header-small post-page-header ${
                location.banner_link ? "location-page-banner" : ""
              }`}
              onClick={() => {
                if (location.banner_link) {
                  window.location = `${location.banner_link}`;
                }
              }}
            >
              <img
                alt={`${location.name}-banner`}
                className="img rounded img-raised location-banner-img"
                src={location.banner.url}
              ></img>
              <div className="content-center"></div>
            </div>
          ) : (
            <span />
          )}
          <div
            className={`section section-sections ${
              location.banner ? "location-header-less-padding" : ""
            }`}
            style={{ paddingTop: "0px" }}
          >
            <Container>
              <Row className="px-3">
                <div className="location-title">
                  <span className="location-label">{location.name}</span>
                </div>
              </Row>
            </Container>
            {(!sub || status === 'cancelled') && selectedCam && displayPremium ? <OGPackage /> : <div />}
            {/* Live Cam */}
            <Container fluid>
              <Row className="location-video-cam-container justify-content-center align-items-center">
                <Col sm={12} md={{ size: 8, offset: 1 }} lg={{ size: 8, offset: 1 }} xl={{ size: 5, offset: 1 }} className="p-0 m-0">
                  {selectedCam ? renderCam() : renderArialImage()}
                </Col>
              </Row>
            </Container>

            <Container className="pl-0 pr-0">
              <div className="location-details">
                <Row className="p-0 m-0 location-details-row">
                  {/* Description */}
                  <Col xs={12} md={6} className="p-0 m-0">
                    <div className="location-info">
                      <div className="location-description">
                        <Description value={location.description} />
                      </div>
                    </div>
                  </Col>

                  {/* Ad Slot 1*/}
                  <LocationAdSlotsTop
                    showClass="d-none d-md-block"
                    slot1={{
                      img:
                        location.ad_slot1_image && location.ad_slot1_image.url,
                      link: location.ad_slot1_link,
                      hide: location.ad_slot1_hide,
                    }}
                    slot2={{
                      img:
                        location.ad_slot2_image && location.ad_slot2_image.url,
                      link: location.ad_slot2_link,
                      hide: location.ad_slot2_hide,
                    }}
                    slot3={{
                      img:
                        location.ad_slot3_image && location.ad_slot3_image.url,
                      link: location.ad_slot3_link,
                      hide: location.ad_slot3_hide,
                    }}
                  />

                  {/* Ad Slot 2*/}
                  <LocationAdSlotsTop
                    showClass="d-block d-md-none"
                    slot1={{
                      img:
                        location.ad_slot1_image && location.ad_slot1_image.url,
                      link: location.ad_slot1_link,
                      hide: location.ad_slot1_hide,
                    }}
                    slot2={{
                      img:
                        location.ad_slot2_image && location.ad_slot2_image.url,
                      link: location.ad_slot2_link,
                      hide: location.ad_slot2_hide,
                    }}
                    slot3={{
                      img:
                        location.ad_slot3_image && location.ad_slot3_image.url,
                      link: location.ad_slot3_link,
                      hide: location.ad_slot3_hide,
                    }}
                  />
                </Row>
              </div>
            </Container>

            {/* <Container className="pl-0 pr-0">
              <div className="location-details">
                <Row className="p-0 m-0 location-details-row">
                  <Col xs={12} md={6} className="p-0 m-0">
                    {selectedCam ? renderArialImage() : <span />}
                  </Col>
                </Row>
              </div>
            </Container> */}

            <div className="location-details">
              <InstallPWABanner />
            </div>
            <ForcastCharts key={`chart-${location.id}`} location={location} />
            {location.ad_slot4_image && (
              <LocationAdSlotBody
                key="body-slot-4"
                slot={{
                  img: location.ad_slot4_image && location.ad_slot4_image.url,
                  link: location.ad_slot4_link,
                }}
              />
            )}
            <Container>
              {coast && coast.video ? (
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <div className="space-50"></div>
                    <VideoPlayer link={coast.video} />
                  </Col>
                </Row>
              ) : (
                <span />
              )}
            </Container>
            {/* <WeatherMapWrapper key={`map-${location.id}`} location={location} /> */}
            <WindyWidget key={`map-widget${location.id}`} location={location} />
            <Container className="pl-0 pr-0">
              {location.video ? (
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <div className="space-50"></div>
                    <VideoPlayer link={location.video} />
                  </Col>
                </Row>
              ) : (
                <span />
              )}
            </Container>
            <div className="space-50"></div>
            {location.cam_1 || location.cam_2 || location.buoy ? (
              <div>
                <CamContainer
                  cam_1={location.cam_1}
                  cam_2={location.cam_2}
                  buoy={location.buoy}
                />
                <div className="space-50"></div>
              </div>
            ) : (
              <span />
            )}
            <Container>
              <SocialLinks />
            </Container>
            {location.ad_slot5_image && (
              <LocationAdSlotBody
                key="body-slot-5"
                slot={{
                  img: location.ad_slot5_image && location.ad_slot5_image.url,
                  link: location.ad_slot5_link,
                }}
              />
            )}
            {posts && posts.length ? (
              <Container>
                <div className="space-50"></div>
                <div className="other-posts">
                  <Row>
                    <Col className="pl-0 pr-0">
                      <h2 className="text-center title">
                        latest posts<br></br>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    {_.map(posts, (post, index) => (
                      // renderCard(post, index, !index ? "d-flex d-md-none" : "")
                      <PostCards
                        key={index}
                        post={post}
                        onSelect={() => handleSelectPost(post)}
                      />
                    ))}
                  </Row>
                </div>
              </Container>
            ) : (
              <div />
            )}
            {location.ad_slot6_image && (
              <LocationAdSlotBody
                key="body-slot-6"
                slot={{
                  img: location.ad_slot6_image && location.ad_slot6_image.url,
                  link: location.ad_slot6_link,
                }}
              />
            )}
          </div>
        </>
      ) : (
        <span />
      )}
    </div>
  );
};

export default LocationSections;
